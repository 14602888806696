<script>
import axios from "axios";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import store from "@/state/store";
export default {
  components:{Navbar,Footer},
  data() {
    return {
      name: {
        val: "",
        isValid: true,
      },
      password: {
        val: "",
        isValid: true,
      },
      email: {
        val: "",
        isValid: true,
      },
      phone: {
        val: "",
      },
      showPass:false,
      user:null,
      formIsValid:true,
      authError:null
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    toggleShowPassword() {
      this.showPass = !this.showPass;
    },
    validateForm(){
      if (this.name.val === "") {
        this.name.isValid = false;
        this.formIsValid = false;
      }
      if (this.email.val === "") {
        this.email.isValid = false;
        this.formIsValid = false;
      }
      if (this.password.val === "") {
        this.password.isValid = false;
        this.formIsValid = false;
      } else {
        this.formIsValid = true;
      }
    },
    async addPatient() {
      this.validateForm();
      if (!this.formIsValid) {
        console.log("Invalid Form");
      } else {
      var formdata = new FormData();
      formdata.append("name", this.name.val);
      formdata.append("password", this.password.val);
      formdata.append("email", this.email.val);
      formdata.append("phone", this.phone.val);
      
      await axios.post(
        " https://api.doctosoft.com/index.php/api/user",
        formdata
      ).then((response) => {
            if (response.data.id)
            {
              this.user = response.data
              console.log(this.user)
            }
            else {
              this.authError = response.data
            }
          });
    console.log(this.authError)
      store.commit("user/setId", this.user.id);
        store.commit("user/setName", this.user.name);
        store.commit("user/setEmail", this.user.email);
        store.commit("user/setPhone", this.user.phone);

      this.$router.push("/");
        }
    },
  },
  computed:{
    passType(){
      return this.showPass ? 'text' : 'password'
    }
  }
};
</script>

<template>
 
    <Navbar/>
    <div class="form-div">
      <h1>SignUp</h1>
      <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{authError}}</b-alert>
      <form class="needs-validation" @submit.prevent="addPatient">
        <div class="mb-3" :class="{invalid : !name.isValid}">
          <label for="name" class="form-label"
            >Name <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            v-model.trim="name.val"
            placeholder="Enter name"
            @blur="clearValidity('name')"
          />
        </div>

        <div class="mb-3 " :class="{invalid : !password.isValid}">
          <label for="password" class="form-label"
            >Password <span class="text-danger">*</span></label
          >
          <div class="passInput">
          <input
            :type="passType"
            class="form-control"
            v-model.trim="password.val"
            placeholder="Enter Password"
            @blur="clearValidity('password')"
          />
          <b-button
              variant="link"
              class="position-absolute text-decoration-none text-muted"
              type="button"
              id="password-addon"
              @click="toggleShowPassword"
            >
              <i class="ri-eye-fill align-middle"></i>
            </b-button>
          </div>
        </div>

        <div class="mb-3" :class="{invalid : !email.isValid}">
          <label for="email" class="form-label"
            >Email <span class="text-danger">*</span></label
          >
          <input
            type="email"
            class="form-control"
            v-model.trim="email.val"
            placeholder="Enter Email"
            @blur="clearValidity('email')"
          />
        </div>
        <div class="mb-3" >
          <label for="phone" class="form-label"
            >Phone <span class="text-danger">(optional)</span></label
          >
          <input
            type="text"
            class="form-control"
            v-model.trim="phone.val"
            placeholder="Enter Phone"
          />
        </div>

        <div class="mt-4">
          <b-button variant="success" class="w-100" type="submit"
            >SignUp</b-button
          >
        </div>
      </form>
    </div>
    <Footer/>

</template>

<style scoped>
.invalid label{
  color: red;
}
.invalid input{
  border: 1px solid red;
}
.form-div {
  margin-left: 50%;
  transform: translateX(-50%);
}
.form-div h1 {
  text-align: center;
}
.navbar {
  display: flex;
  padding: 0 1rem;
  background-color: rgb(234, 234, 255);
  align-items: center;
  height: 10vh;
  margin-bottom: 5px;
}

.links {
  padding: 10px 0px;
  display: flex;
}

.links .btn {
  border-radius: 5px;
}

.btn a {
  border: 1px solid #2aa2db;
  padding: 10px 25px;
  color: #2aa2db;
  border-radius: 10px;
}
.column {
  display: flex;
  flex-direction: column;
}

.form-div {
  max-width: 30rem;
  padding: 1rem;
}
.w-100{
  height: 40px;
}
.passInput {
  position: relative;
}
.passInput button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
</style>
